<!-- 
	This is the sign in page, it uses the dashboard layout in:
	"./layouts/Default.vue" .
 -->

<template>
  <div class="sign-in">

    <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

      <!-- Sign In Form Column -->
      <a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 2}" class="col-form">
        <h2 class="mb-15">跟单猿系统注册</h2>
        <!--				<h5 class="font-regular text-muted">内测中，暂不支持注册……</h5>-->

        <!-- Sign In Form -->
        <a-form
            id="components-form-demo-normal-login"
            :form="form"
            class="login-form"
            @submit="handleSubmit"
            :hideRequiredMark="true"
        >
          <a-form-item class="mb-10" label="用户名" :colon="false">
            <a-input
                v-decorator="[
						'username',
						{ rules: [{ required: true, message: '请输入你的用户名！' }] },
						]" placeholder="用户名/邮箱" />
          </a-form-item>
          <a-form-item class="mb-5" label="密码" :colon="false">
            <a-input
                v-decorator="[
						'password',
						{ rules: [{ required: true, message: '请输入你的密码！' }, { min: 6, message: '密码长度不能少于6位！' }] },
						]" type="password" placeholder="密码" />
          </a-form-item>
          <a-form-item class="mb-5" label="确认密码" :colon="false">
            <a-input
                v-decorator="[
						'confirm_password',
						{ rules: [{ required: true, message: '请重复输入你的密码！' }, { min: 6, message: '密码长度不能少于6位！' }] },
						]" type="password" placeholder="确认密码" />
          </a-form-item>
          <a-form-item class="mb-10">
            <a-checkbox
                v-decorator="[
						'remember',
						{
							valuePropName: 'checked',
							initialValue: true,
						},
						]"
            >
              我同意 <a href="#" class="font-bold text-dark">《用户协议》</a>
            </a-checkbox>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" block html-type="submit" class="login-form-button" @click="handleSubmit">
              注 册
            </a-button>
          </a-form-item>
        </a-form>
        <!-- / Sign In Form -->

        <p class="font-semibold text-muted">已有账号？ <router-link to="/sign-in" class="font-bold text-dark">登录</router-link></p>

      </a-col>
      <!-- / Sign In Form Column -->

      <!-- Sign In Image Column -->
      <a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
        <img src="images/img-logo.png" alt="">
      </a-col>
      <!-- Sign In Image Column -->

    </a-row>

  </div>
</template>

<script>
import http from "../utils/http"

export default ({
  data() {
    return {
      // Binded model property for "Sign In Form" switch button for "Remember Me" .
      // rememberMe: true,
    }
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: 'normal_login' });
  },
  created() {
    const isLoggedIn = this.$store.state.isLogin; // 从 Vuex 状态中获取登录状态
    if (isLoggedIn) {
      this.$router.push('/dashboard');
    }
  },
  methods: {
    // Handles input validation after submission.
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if ( !err ) {
          console.log('Received values of form: ', values) ;
        }
        // 注册请求处理
        this.signUp(values.username, values.password, values.confirm_password).then(response=>{
          if (response.data.code === 0) {

            // 注册成功
            this.$message.success(values.username+"注册成功！");
            this.$router.push("/dashboard");
          } else {
            // 登录失败
            this.$message.error(response.data.detail.username);
          }
        }).catch(error=>{
          console.log(error);
        })

      });
    },
    signUp(username, password, confirm_password) {
      // 检查密码
      if (password !== confirm_password) {
        this.$message.error("两次密码输入不一致！");
        return;
      }
      // 用户登录
      return http.post("/api/register/", {
        username: username,
        password: password,
        confirm_password: confirm_password,
      });
    }
  },
})

</script>

<style lang="scss">
body {
  background-color: #ffffff;
}
</style>