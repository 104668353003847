var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-in"},[_c('a-row',{attrs:{"type":"flex","gutter":[24,24],"justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"col-form",attrs:{"span":24,"md":12,"lg":{span: 12, offset: 0},"xl":{span: 6, offset: 2}}},[_c('h2',{staticClass:"mb-15"},[_vm._v("跟单猿系统注册")]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"用户名","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'username',
						{ rules: [{ required: true, message: '请输入你的用户名！' }] } ]),expression:"[\n\t\t\t\t\t\t'username',\n\t\t\t\t\t\t{ rules: [{ required: true, message: '请输入你的用户名！' }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"用户名/邮箱"}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"密码","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'password',
						{ rules: [{ required: true, message: '请输入你的密码！' }, { min: 6, message: '密码长度不能少于6位！' }] } ]),expression:"[\n\t\t\t\t\t\t'password',\n\t\t\t\t\t\t{ rules: [{ required: true, message: '请输入你的密码！' }, { min: 6, message: '密码长度不能少于6位！' }] },\n\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"密码"}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"确认密码","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'confirm_password',
						{ rules: [{ required: true, message: '请重复输入你的密码！' }, { min: 6, message: '密码长度不能少于6位！' }] } ]),expression:"[\n\t\t\t\t\t\t'confirm_password',\n\t\t\t\t\t\t{ rules: [{ required: true, message: '请重复输入你的密码！' }, { min: 6, message: '密码长度不能少于6位！' }] },\n\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"确认密码"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'remember',
						{
							valuePropName: 'checked',
							initialValue: true,
						} ]),expression:"[\n\t\t\t\t\t\t'remember',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tvaluePropName: 'checked',\n\t\t\t\t\t\t\tinitialValue: true,\n\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}]},[_vm._v(" 我同意 "),_c('a',{staticClass:"font-bold text-dark",attrs:{"href":"#"}},[_vm._v("《用户协议》")])])],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"},on:{"click":_vm.handleSubmit}},[_vm._v(" 注 册 ")])],1)],1),_c('p',{staticClass:"font-semibold text-muted"},[_vm._v("已有账号？ "),_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":"/sign-in"}},[_vm._v("登录")])],1)],1),_c('a-col',{staticClass:"col-img",attrs:{"span":24,"md":12,"lg":12,"xl":12}},[_c('img',{attrs:{"src":"images/img-logo.png","alt":""}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }